<template>
  <app-module-view>
    <template slot="body">
      <section class="m-b-10">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-right m-r-5">
              <app-button-delete v-if="isDeleteButtonVisible" @deleteRecord="deleteAuthor" />
              <app-button-close route-name="author_list"></app-button-close>
            </div>
          </div>
        </div>
      </section>
      <div class="m-b-10">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-right m-r-5">

            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <table class="table table-striped table-bordered">
            <tbody>
            <tr>
              <th width="200">{{ $t('system.id') }}</th>
              <td>{{ author.id }}</td>
            </tr>
            <tr>
              <th>{{ $t('author.name') }}</th>
              <td>{{ author.name }}</td>
            </tr>
            <tr>
              <th>{{ $t('author.originName') }}</th>
              <td>{{ author.originName }}</td>
            </tr>
            <tr>
              <th>{{ $t('author.slug') }}</th>
              <td>{{ author.slug }}</td>
            </tr>
            <tr>
              <th>{{ $t('author.position') }}</th>
              <td>{{ author.position }}</td>
            </tr>
            <tr>
              <th>{{ $t('author.description') }}</th>
              <td>{{ author.description }}</td>
            </tr>
            <tr>
              <th>{{ $t('author.email') }}</th>
              <td>{{ author.email }}</td>
            </tr>
            <tr>
              <th>{{ $t('author.image') }}</th>
              <td>
                <app-media
                  v-if="authorImage"
                  :media="authorImage"
                  :width="480"
                  :height="270"
                  show-media-info
                >
                </app-media>
              </td>
            </tr>
            <tr>
              <th>{{ $t('author.type') }}</th>
              <td>{{ author.type }}</td>
            </tr>
            <tr>
              <th>{{ $t('author.image') }}</th>
              <td>{{ author.hiddenOnPage }}</td>
            </tr>
            <tr>
              <th>{{ $t('author.disabled') }}</th>
              <td>{{ author.disabled }}</td>
            </tr>
            <tr>
              <th>{{ $t('author.detailPage') }}</th>
              <td>{{ author.detailPage }}</td>
            </tr>
            <tr>
              <th>{{ $t('author.facebook') }}</th>
              <td>{{ author.social.facebook }}</td>
            </tr>
            <tr>
              <th>{{ $t('author.google') }}</th>
              <td>{{ author.social.google }}</td>
            </tr>
            <tr>
              <th>{{ $t('author.twitter') }}</th>
              <td>{{ author.social.twitter }}</td>
            </tr>
            <tr>
              <th>{{ $t('author.instagram') }}</th>
              <td>{{ author.social.instagram }}</td>
            </tr>
            </tbody>
          </table>
          <app-detail-system-rows :record="author"></app-detail-system-rows>
        </div>
      </div>
    </template>
  </app-module-view>
</template>

<script>
import ModuleView from '../../components/ModuleView'
import DetailSystemRows from '../../components/shared/DetailSystemRows'
import AuthorModel from '../../model/AuthorModel.js'
import ButtonDelete from '../../components/shared/ButtonDelete'
import ButtonClose from '../../components/shared/ButtonClose'
import Media from '../../components/shared/Media'
import NotifyService from '../../services/NotifyService'
import PermissionService from '@/services/PermissionService'

export default {
  name: 'AuthorView',
  data () {
    return {
      author: AuthorModel,
      error: null
    }
  },
  computed: {
    authorImage () {
      return this.$store.getters['author/authorImage']
    }
  },
  components: {
    appModuleView: ModuleView,
    appButtonDelete: ButtonDelete,
    appButtonClose: ButtonClose,
    appMedia: Media,
    appDetailSystemRows: DetailSystemRows
  },
  methods: {
    isDeleteButtonVisible () {
      const permission = PermissionService.REQUIRED_PERMISSIONS.SYSTEM_AUTHOR_PERMISSIONS.deleteButton
      return this.$store.getters['user/hasPermission'](permission)
    },
    getAuthor () {
      this.$store.dispatch('author/fetchOne', this.$route.params.id)
        .then(() => {
          this.author = this.$store.getters['author/detail']
        })
    },
    deleteAuthor () {
      this.$store.dispatch('author/deleteRecord', this.author)
        .then(() => {
          if (this.$store.getters['author/error'] === null) {
            NotifyService.setSuccessMessage(this.$t('notify.record_was_deleted'))
            this.$router.push('/author')
          } else {
            NotifyService.setErrorMessage(this.$store.getters['author/error'])
          }
        })
        .catch(error => console.log(error))
    }
  },
  mounted () {
    this.getAuthor()
  }
}
</script>
